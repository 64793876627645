.App {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;

    @media (max-width: 768px) {
        height: auto;
        width: 100%;
    }
}
